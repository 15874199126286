<template>
  <h3 v-if="object?.view?.default?.showTitle">
    {{ object?.view?.default?.customTitle?.length ? object?.view?.default?.customTitle : blueprint?.name }}
  </h3>
  <a-spin :spinning="contentHtmlLoading" style="width: 100%">
    <RenderHtml v-if="renderedContentHtml" @buttonClicked="renderHtmlButtonClicked" :key="renderHtmlTemplateKey" :content="renderedContentHtml" />
  </a-spin>
  <div class="chart-container">
    <chartComponent
      :chartData="chartData"
      :chartValues="object.view.default.chart.values"
      :chartCustomValues="object.view.default.chart.customValues ?? []"
      :chartListFields="object.view.listFields"
      :chartTypeValues="object.view.default.chart.chartTypeValues"
      :chartConcatLabels="object.view.default.chart.concatField ?? ''"
    />
  </div>
  <a-spin :spinning="contentHtmlLoading" style="width: 100%">
    <RenderHtml
      v-if="renderedContentHtmlAfter"
      @buttonClicked="renderHtmlButtonClicked"
      :key="renderHtmlTemplateKeyAfter"
      :content="renderedContentHtmlAfter"
    />
  </a-spin>
</template>
<script>
import RenderHtml from '@templateDataSystem/views/components/RenderHtml';
import { objectId } from '@/core/utils/array-manipulation';

import { UserGetters } from '@/apps/userManagement/user.store';
import { NewTemplatesGetters, NewTemplatesActions } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import { TemplateDataSerializer } from '@/apps/templateDataSystem/shared/TemplateDataSerializer';

import { defineAsyncComponent } from 'vue';

export default {
  components: {
    RenderHtml,
    chartComponent: null,
  },
  props: ['object', 'loadMoreView', 'viewType', 'lastDepth'],
  data() {
    return {
      deleteLoading: null,
      chartLegendField: null,
      chartLegendInstances: [],
      instance: null,
      chartData: [],
      contentHtml: null,
      renderHtmlTemplateKey: objectId(),
      renderHtmlTemplateKeyAfter: objectId(),
    };
  },
  created() {
    if (this.object.view.default.chart.chartType === 'pieChart') {
      this.$options.components.chartComponent = defineAsyncComponent(
        () => import('@/apps/templateDataSystem/views/components/GoogleCharts/components/PieChart.vue')
      );
    }
  },
  async mounted() {
    this.chartLegendField = this.blueprint.fields.find(field => field._id.toString() === this.object.view.default.chart.legend);
    await this.fetchInstances(null, null, null, this.chartLegendField.structure.ruleset.blueprintId, true);
    const instances = NewTemplatesGetters.getInstanceList(this.object._id, this.chartLegendField.structure.ruleset.blueprintId);
    this.chartLegendInstances = instances?.instanceList ?? [];
    await this.fetchInstances(null, null, null, this.object.blueprintId);
    this.fetchChartData();
  },
  computed: {
    user: UserGetters.getUser,
    renderedContentHtml() {
      return NewTemplatesGetters.getContentHtml(this.object._id, this.object.view.type);
    },
    renderedContentHtmlAfter() {
      return NewTemplatesGetters.getContentHtmlAfter(this.object._id, this.object.view.type);
    },
    contentHtmlLoading() {
      return NewTemplatesGetters.getContentHtmlLoading(this.object._id);
    },
    blueprint() {
      return NewTemplatesGetters.getBlueprint(this.object.blueprintId);
    },
    filterConditions() {
      return NewTemplatesGetters.getFilterConditions(this.object._id);
    },
  },
  methods: {
    renderHtmlButtonClicked(data) {
      this.rowClick(data);
    },
    fetchChartData() {
      const data = [];
      const instances = NewTemplatesGetters.getInstanceList(this.object._id, this.object.blueprintId);
      const chartInstances = TemplateDataSerializer.instanceListToData(instances?.instanceList ?? [], this.object, this.blueprint);
      if (Array.isArray(this.chartLegendInstances) && this.chartLegendInstances.length > 0) {
        this.chartLegendInstances.forEach(legendInstance => {
          const filteredChartData = chartInstances.filter(
            chartInstance => chartInstance[`${this.object.view.default.chart.legend}-original-value`] === legendInstance._id
          );
          data.push({
            title: legendInstance[this.object.view?.default?.chart?.legendTitle],
            _id: legendInstance._id,
            chartValues: filteredChartData ?? [],
          });
        });
      }
      this.chartData = data;
    },
    async fetchInstances(pagination, filters, sorter, blueprintId, noFilters = false) {
      const convertOwnerIdToOwnerDetails = this.object.view?.default?.convertOwnerIdToOwnerDetails ?? false;

      let query = NewTemplatesGetters.getInstanceListQueryParams(this.object._id, blueprintId);
      if (!noFilters) {
        query = {
          convertOwnerIdToOwnerDetails,

          ...query,

          objectId: this.object._id,
          templateId: NewTemplatesGetters.getMainTemplateId(),
          filterConditions: noFilters ? [] : (this.object.view?.filters ?? []),
        };
        if (sorter?.field) {
          query.sortFieldId = sorter.field;
        }
        if (sorter?.order) {
          query.sortOrder = sorter.order;
        }

        if (filters) {
          query.filters = filters;
        }
      } else {
        query = {
          ...query,
          objectId: this.object._id,
          filterConditions: noFilters ? [] : (this.object.view?.filters ?? []),
          templateId: null,
        };
      }
      await NewTemplatesActions.fetchInstanceList(this.object._id, blueprintId, query);
    },
  },
};
</script>

<style scoped>
.chart-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%;
  min-height: 500px;
  padding: 5px;
  box-sizing: border-box;
}
</style>
