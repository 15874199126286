<template>
  <div>
    <a-checkbox style="margin: 15px 0" v-model:checked="input.isEnabled">
      Enable Conditional Logic
      <help-icon-popover title="Conditional Logic" content="description" />
    </a-checkbox>

    <div style="padding: 0 27.5px" v-if="input.isEnabled">
      <a-select v-model:value="input.action" default-value="show">
        <a-select-option value="show">Show</a-select-option>
        <a-select-option value="hide">Hide</a-select-option>
      </a-select>
      <span style="margin: 0 10px">this field if</span>
      <a-select v-model:value="input.mode" default-value="all">
        <a-select-option value="all">All</a-select-option>
        <a-select-option value="any">Any</a-select-option>
      </a-select>
      <span style="margin: 0 10px">of the following match:</span>

      <div v-for="(condition, index) in input.conditions" :key="index" style="margin: 15px 0">
        <span style="margin-right: 15px; font-weight: 500; margin-top: -10px"> {{ index + 1 }}. </span>
        <a-select
          placeholder="Select field"
          style="min-width: 200px; margin-right: 15px"
          v-model:value="condition.fieldId"
          @change="setConditionComparator(condition)"
        >
          <a-select-option v-for="field in fieldsBeforeThis" :key="field._id" :value="field._id">
            (ID: {{ field.successionIndex }}) {{ field.label }}
          </a-select-option>
        </a-select>
        <a-select v-model:value="condition.comparator" style="min-width: 150px" default-value="equal">
          <a-select-option value="EQUAL">is equal to</a-select-option>
          <a-select-option value="NOT_EQUAL">is not equal to</a-select-option>
          <a-select-option value="VISIBLE">is visible</a-select-option>
          <a-select-option value="NOT_VISIBLE">is not visible</a-select-option>
          <a-select-option value="VALID">is valid</a-select-option>
          <a-select-option value="NOT_VALID">is not valid</a-select-option>
          <a-select-option value="CONTAINS" v-if="fieldIsMultiple(condition.fieldId)">contains</a-select-option>
          <a-select-option value="NOT_CONTAINS" v-if="fieldIsMultiple(condition.fieldId)">not contains</a-select-option>
          <a-select-option value="HAS_VALUES">has values</a-select-option>
          <a-select-option value="DOESENT_HAVE_VALUES">doesen't have values</a-select-option>
        </a-select>
        <div v-if="!['VALID', 'NOT_VALID', 'HAS_VALUES', 'DOESENT_HAVE_VALUES'].includes(condition.comparator) && condition.fieldId">
          <ExpectedValueInput
            :fieldId="condition.fieldId"
            :blueprintId="
              condition.blueprintId ? condition.blueprintId : fieldsBeforeThis.find(f => f._id === condition?.fieldId).structure?.ruleset?.blueprintId
            "
            style="margin-top: 10px; display: inline-block; width: 355px"
            :condition="condition"
            @expectedValue="setCondition($event, condition)"
          />
          <a-tooltip title="Remove condition" style="float: right">
            <a-button @click="onClickRemoveCondition(index)" style="margin-left: 5px">
              <MinusOutlined />
            </a-button>
          </a-tooltip>
        </div>
        <a-tooltip title="Remove condition" style="float: right" v-if="['VALID', 'NOT_VALID'].includes(condition.comparator)">
          <a-button @click="onClickRemoveCondition(index)" style="margin-left: 5px">
            <MinusOutlined />
          </a-button>
        </a-tooltip>
      </div>
      <a-button @click="onClickAddCondition" style="margin-top: 10px"> <PlusOutlined /> Add condition </a-button>
    </div>
  </div>
</template>

<script>
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

import { FormBuilderGetters } from '../../../formBuilder.store';

import ExpectedValueInput from './ExpectedValueInput.vue';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'logic.conditional',
    keys: ['isEnabled', 'action', 'mode', 'conditions'],
  },
  components: {
    ExpectedValueInput,
    MinusOutlined,
    PlusOutlined,
  },
  computed: {
    fieldsBeforeThis() {
      return FormBuilderGetters.getFieldsBefore(this.fieldId);
    },
  },
  methods: {
    fieldIsMultiple(fieldId) {
      return this.fieldsBeforeThis.find(f => f._id === fieldId)?.array;
    },
    setConditionComparator(condition) {
      const selectedField = this.fieldsBeforeThis.find(f => f._id === condition?.fieldId);
      if (selectedField && !selectedField.array && condition.comparator === 'CONTAINS') {
        condition.comparator = 'EQUAL';
      }
      if (selectedField && !selectedField.array && condition.comparator === 'NOT_CONTAINS') {
        condition.comparator = 'NOT EQUAL';
      }
      if (selectedField?.structure?.type === 'reference') {
        condition.blueprintId = selectedField?.structure?.ruleset?.blueprintId ?? null;
      }
    },
    onClickAddCondition() {
      this.input.conditions.push({
        fieldId: null,
        comparator: 'EQUAL',
        expectedValue: null,
      });
    },
    onClickRemoveCondition(index) {
      this.input.conditions.splice(index, 1);
    },
    setCondition(conditionObj, condition) {
      condition.expectedValue = conditionObj.value;
      if (conditionObj.subtenantSlug) {
        condition.subtenantSlug = conditionObj.subtenantSlug;
      }
    },
  },
};
</script>

<style></style>
